import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { CommonHelper } from '../backoffice/service/helper/commonhelper';
import { AuthService } from '../backoffice/service/auth/auth.service';
import { BoRolesService } from '../backoffice/service/api/bo-roles.service';
import { BoRolePageRights } from '../backoffice/models/bo-role-page-rights';

interface MergedPageRight {
    id: number;
    hasView: boolean;
    pageUrl: string;
}

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    userRole: { id: number, name: string }[] = [];
    

    constructor(public layoutService: LayoutService, private boRolesService: BoRolesService, private authService: AuthService) { }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            CommonHelper.getUserRole(this.boRolesService).then((userRoles) => {
                // Declare mergedPageRightsMap here
                const mergedPageRightsMap: Map<number, MergedPageRight> = new Map();

                if (userRoles.length > 0) {
                    const fetchPageRights = (index: number) => {
                        if (index < userRoles.length) {
                            const userRole = userRoles[index];

                            this.boRolesService.getRolePageRights(0, 1000, userRole.id).then((response: any) => {
                                if (response && response.data) {
                                    const pageRights: BoRolePageRights[] = response.data;

                                    // Merge the hasView and pageUrl values for each id
                                    pageRights.forEach((right) => {
                                        const existingRight = mergedPageRightsMap.get(right.id);
                                        if (existingRight) {
                                            existingRight.hasView = existingRight.hasView || right.hasView;
                                        } else {
                                            mergedPageRightsMap.set(right.id, { id: right.id, hasView: right.hasView, pageUrl: right.pageUrl });
                                        }
                                    });

                                    // Fetch page rights for the next user role
                                    fetchPageRights(index + 1);
                                } else {
                                    console.error('Invalid response format: ', response);

                                    // Fetch page rights for the next user role even if there's an error
                                    fetchPageRights(index + 1);
                                }
                            });
                        } else {
                            // All page rights fetched, now create an array with merged results
                            const mergedPageRights: MergedPageRight[] = Array.from(mergedPageRightsMap.values());

                            // Now call buildMenu with the merged results
                            this.buildMenu(mergedPageRights);
                        }
                    };

                    // Start fetching page rights for the first user role
                    fetchPageRights(0);
                } else {
                    console.error('No user roles found');
                }
            });
        }
    }
    

    buildMenu(pageRights: any[]) {
        this.model = [
            {
                label: 'Management',
                items: [
                    {
                        label: 'Projects',
                        icon: 'pi pi-fw pi-sitemap',
                        items: [
                            {
                                label: 'Project List',
                                icon: 'pi pi-fw pi-desktop',
                                routerLink: ['/projects/project-list']
                            },

                            {
                                label: 'Project Quarters',
                                icon: 'pi pi-fw pi-calendar',
                                routerLink: ['/projects/project-quarters']
                            }
                        ]
                    },
                    {
                        label: 'Surveyors List',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/surveyors/surveyor-list']
                    }
                ]
            },


            {
                label: 'User And Role Management',
                items: [
                    { label: 'User Management', icon: 'pi pi-fw pi-user', routerLink: ['/users/user-management'] },
                    { label: 'Role Management', icon: 'pi pi-fw pi-id-card', routerLink: ['/users/role-management'] },

                ]
            },
            {
                label: 'Data Management',
                items: [
                    { label: 'DHB Management', icon: 'pi pi-fw pi-id-card', routerLink: ['/area/dhb-management'] },
                    { label: 'Region Management', icon: 'pi pi-fw pi-id-card', routerLink: ['/area/region-management'] },
                    { label: 'Workplan', icon: 'pi pi-fw pi-book', routerLink: ['/area/workplan-management'] },
                    { label: 'TravelLog', icon: 'pi pi-fw pi-globe', routerLink: ['/area/travellog-management'] },
                    { label: 'Expense', icon: 'pi pi-fw pi-money-bill', routerLink: ['/area/expense-management'] },
                    { label: 'Meshblock Management', icon: 'pi pi-fw pi-id-card', routerLink: ['/area/meshblock-management'] },
                    {
                        label: 'Household Management',
                        icon: 'pi pi-fw pi-id-card',
                        items:[
                            {
                                label: 'Household Lists',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/area/household-management']
                            },
                            {
                            label: 'Contact Responses',
                            icon: 'pi pi-fw pi-id-card',
                            items: [
                                { label: 'Contact History', icon: 'pi pi-fw pi-book', routerLink: ['/area/contact-history'] },
                                { label: 'Household Response', icon: 'pi pi-fw pi-clone', routerLink: ['/area/household-response'] },
                                { label: 'Occupant Response', icon: 'pi pi-fw pi-home',routerLink: ['/area/occupant-response'] }
                                ]
                            },
                            { 
                            label: 'Measurements', 
                            icon: 'pi pi-fw pi-id-card',
                            items: [
                                    {label: 'BP Measurements', icon: 'pi pi-fw pi-card', routerLink: ['/area/measurement-bp']},
                                    {label: 'Body Measurements', icon: 'pi pi-fw pi-card', routerLink: ['/area/measurement-body']},
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Calendar',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['/area/calendar']
                    },
                    { label: 'Respondents ', icon: 'pi pi-fw pi-user', routerLink: ['/area/respondent-management'] },
                    { label: 'Targets ', icon: 'pi pi-fw pi-circle-on', routerLink: ['/area/target-management'] },
                ]
            },
            {
                label: 'GPS Device Management',
                items: [
                    { label: 'GPS Devices', icon: 'pi pi-fw pi-calculator', routerLink: ['/area/gps-device-management'] },
                    { label: 'Device Audit', icon: 'pi pi-fw pi-calculator', routerLink: ['/area/gps-device-audit'] },
                    { label: 'Bulk Assignments', icon: 'pi pi-fw pi-calculator', routerLink: ['/area/gps-device-bulk'] },
                ]
            },
            {
                label: 'Lookup Data',
                items: [
                    { label: 'Dwelling Status', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/dwelling-status'] },
                    { label: 'Dwelling Type', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/dwelling-type'] },
                    { label: 'Ethnicity', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/ethnicity'] },
                    { label: 'Gender', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/gender'] },
                    { label: 'Note Type', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/note-type'] },
                    { label: 'Document Type', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/document-type'] },
                    { label: 'Lookup Responses', icon: 'pi pi-fw pi-id-card',
                      items: [
                            {label: 'Contact History', icon: 'pi pi-fw pi-book', routerLink: ['/lookup/contact-history']},
                            {label: 'Household Response', icon: 'pi pi-fw pi-clone', routerLink: ['/lookup/household-response']},
                            {label: 'Occupant Response', icon: 'pi pi-fw pi-home', routerLink: ['/lookup/occupant-response']}
                      ]
                    },                    
                    { label: 'Payrun Period', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/payrun-period']},
                    { label: 'Target Period', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/target-period'] },
                    { label: 'Measurement Code', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/measurement-code'] },
                    { label: 'Measurement Code BP', icon: 'pi pi-fw pi-id-card', routerLink: ['/lookup/measurement-code-bp'] },
                ]
            }

        ];

        const normalizeUrl = (url: string) => url.replace(/^\/+/, '');

        // Helper function to check if any submenu has 'hasView' true
        const hasSubmenuWithView = (submenu: any[], pageRights: BoRolePageRights[]) => {
            for (const subMenuItem of submenu) {
                if (subMenuItem.routerLink) {
                    const normalizedRouterLink = normalizeUrl(subMenuItem.routerLink[0]);
                    const matchedRight = pageRights.find(right => {
                        const normalizedPageUrl = normalizeUrl(right.pageUrl);
                        return normalizedPageUrl === normalizedRouterLink;
                    });
                    if (matchedRight && matchedRight.hasView) {
                        return true;
                    }
                }
            }
            return false;
        };

        this.model.forEach(menuItem => {
            this.updateVisibleProperty(menuItem, pageRights);
        });
        
    }

    /**
     * Update menu 'visible' property
     * 
     * @param menu
     * @param pageRights 
     */
    updateVisibleProperty(menu: any, pageRights: BoRolePageRights[]) {
        const normalizeUrl = (url: string) => url.replace(/^\/+/, '');

        if (Array.isArray(menu.items)) {
            menu.items = menu.items.map(subMenuItem => {
                if (subMenuItem.routerLink) {
                    const normalizedRouterLink = normalizeUrl(subMenuItem.routerLink[0]);
                    const matchedRight = pageRights.find(right => {
                        const normalizedPageUrl = normalizeUrl(right.pageUrl);
                        return normalizedPageUrl === normalizedRouterLink;
                    });
                    subMenuItem.visible = matchedRight ? matchedRight.hasView === true : false;
                }
                if (subMenuItem.items) {
                    this.updateVisibleProperty(subMenuItem, pageRights);
                }
                return subMenuItem;
            });
            menu.visible = menu.items.some(subMenuItem => subMenuItem.visible);
        } else {
            if (menu.routerLink) {
                const normalizedRouterLink = normalizeUrl(menu.routerLink[0]);
                const matchedRight = pageRights.find(right => {
                    const normalizedPageUrl = normalizeUrl(right.pageUrl);
                    return normalizedPageUrl === normalizedRouterLink;
                });
                menu.visible = matchedRight ? matchedRight.hasView === true : false;
            }
        }
    }
}